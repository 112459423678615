import styled from 'styled-components/macro';

import { fontHeading, transit, COLORS } from 'src/style';

interface Props {
  width?: string;
  maxWidth?: string;
  padding?: string;
  background?: string;
}

export const Input = styled.input<Props>`
  border: 0.1rem solid ${COLORS.grey.secondary};
  border-radius: 0.4rem;
  font-size: 1.8rem;
  padding: ${({ padding }: Props): string => padding || '1rem'};
  width: ${({ width }: Props): string => width || '40rem'};
  max-width: ${({ maxWidth }: Props): string | undefined =>
    maxWidth || undefined};
  background-color: ${({ background }: Props): string => background || 'none'};
  margin-right: -2.5rem;
  ${transit()}
  &::placeholder {
    font: ${fontHeading(1.7)};
    color: ${COLORS.grey.primary};
  }
  &:focus {
    border: 0.1rem solid ${COLORS.blue.secondary};
  }
`;

export const Label = styled.div`
  font: ${fontHeading(2)};
  @media (max-width: 698px) {
    width: 40rem;
    margin-bottom: 1rem;
    padding-left: 0;
  }
`;

interface InlineProps {
  single?: boolean;
}

export const Inline = styled.div<InlineProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  left: ${({ single }: InlineProps): string =>
    single ? '-0.5rem' : '-3.5rem'};
  background-color: ${COLORS.white};
`;
