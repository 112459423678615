import React from 'react';

import {
  Triangle,
  Tooltip,
  TooltipSide,
  Rectangle,
  TooltipWrapper,
} from './Tooltip.style';
import { COLORS, H5 } from 'src/style';

export { TooltipSide } from './Tooltip.style';

interface MenuTooltipProps {
  message?: boolean;
  label: string;
  side?: TooltipSide;
  width?: number;
}

/**
 * React component to render menu item tooltip
 * @param {label} Tooltip label to render
 * @returns React.ReactElement
 */
export default ({
  message,
  label,
  side,
  width,
}: MenuTooltipProps): React.ReactElement => (
  <TooltipWrapper>
    <Tooltip data-testid='menu__item_tooltip' side={side} width={width}>
      <Triangle data-testid='menu__item_tooltip_triangle' side={side} />
      <Rectangle data-testid='menu__item_tooltip_rectangle'>
        <H5
          message={message}
          data-testid='menu__item_tooltip_h5label'
          color={COLORS.white}
        >
          {label}
        </H5>
      </Rectangle>
    </Tooltip>
  </TooltipWrapper>
);
