import styled from 'styled-components/macro';

interface Props {
  isOn: boolean;
}

const Message = styled.div<Props>`
  position: fixed;
  max-width: 60%;
  top: 9rem;
  right: 1rem;
  z-index: 101;
  ${({ isOn }: Props): string | undefined =>
    isOn ? undefined : 'display:none;'}
`;

export default Message;
