import React from 'react';

import { COLORS, Line, P5 } from 'src/style';

interface Props {
  status: string;
}

export default ({ status }: Props): React.ReactElement => (
  <Line justify='center' marginVert={1} height='2rem'>
    <P5
      color={COLORS.grey.primary}
      textAlign='center'
      margin={document.body.clientWidth < 450 ? '0 1rem' : undefined}
    >
      {status}
    </P5>
  </Line>
);
