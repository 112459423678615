export type Action = {
  type: string;
  payload?: any;
};

export enum UserActions {
  SET_ADDRESS = 'set_address',
}

export enum InboxActions {
  HANDLE_CHECK_ADDRESS = 'handle_check_address',
  HANDLE_COLLECT = 'handle_collect',
  HANDLE_RETURN = 'handle_return',
  HANDLE_UPDATE_INBOX = 'handle_update_inbox',
  IS_INBOX = 'is_inbox',
  SET_INBOX = 'set_inbox',
  SET_IS_ONLINE = 'set_is_online',
  SET_SELECTED = 'set_selected',
  SET_STATUS = 'set_status',
  SET_TIP = 'set_tip',
  SET_TRANSACTION_LOADING = 'set_trx_loading',
  UPDATE_TRANSACTION = 'update_transaction',
  TRANSACTION_REMOVED = 'transaction_removed'
}

export enum ApplicationActions {
  CHANGE_MODULE = 'change_module',
  CHANGE_PATH = 'change_path',
  SET_LOADING = 'set_loading',
  SET_MESSAGE = 'set_message',
}
