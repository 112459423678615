import React from 'react';

import { H1, P5 } from 'src/style';

import Title from './Title.style';

interface Props {
  opposite: boolean;
  title: string;
}

export default ({ opposite, title }: Props): React.ReactElement => (
  <Title>
    {opposite ? (
      <P5 textAlign='center'>{title}</P5>
    ) : (
      <H1 textAlign='center'>{title}</H1>
    )}
  </Title>
);
