import React from 'react';

import { Line, P5 } from 'src/style';

import { capitalize } from 'src/process/tools';

interface Props {
  state: string;
}

export default ({ state }: Props): React.ReactElement => (
  <Line justify='flex-start' width='undefined' height='3.2rem'>
    <P5>{capitalize(state)}</P5>
  </Line>
);
