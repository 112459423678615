import { UserState, INITIAL_USER_STATE } from 'src/store/models/user';
import { UserActions } from 'src/store/actions';

export default (
  state: UserState = INITIAL_USER_STATE,
  action: { type: UserActions; payload?: string | undefined },
): UserState => {
  if (action.type === UserActions.SET_ADDRESS)
    return { ...state, address: action.payload || '' };
  return state;
};
