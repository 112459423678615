import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { log, LogColors } from 'src/process/tools';

import { ApplicationActions } from 'src/store/actions';

interface Props {
  to: string;
  from?: string;
  message?: string;
}

export default ({ to, from, message }: Props): React.ReactElement => {
  const pathFrom = from || '/';

  if (process.env.NODE_ENV === 'development')
    log(LogColors.INFO, `redirecting ${pathFrom} -> ${to}`);

  if (message) {
    const dispatch = useDispatch();
    dispatch({
      type: ApplicationActions.SET_MESSAGE,
      payload: { code: 500, text: message },
    });
  }

  return (
    <Redirect
      to={{
        pathname: to,
        state: { pathFrom },
      }}
    />
  );
};
