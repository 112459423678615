import {
  EventTypes,
  Message as ServiceMessage,
} from '@kiroboio/safe-transfer-lib';

import {
  ApplicationState,
  INITIAL_APPLICATION_STATE,
  Message,
  EMPTY_MESSAGE,
} from 'src/store/models/app';
import { ApplicationActions } from 'src/store/actions';
import { isMessage } from 'src/process/tools';

export default (
  state: ApplicationState = INITIAL_APPLICATION_STATE,
  action: {
    type: ApplicationActions | EventTypes;
    payload?: string | boolean | Message | ServiceMessage | undefined;
  },
): ApplicationState => {
  switch (action.type) {
    case ApplicationActions.CHANGE_MODULE:
      if (action.payload === state.module) return state;
      const modul = action.payload as string;
      return {
        ...state,
        module: modul && !modul.includes(' ') ? modul : 'welcome',
      };
    case ApplicationActions.CHANGE_PATH:
      if (action.payload === state.path) return state;
      const path = action.payload as string;
      return {
        ...state,
        path: path && !path.includes(' ') ? path : '/',
      };
    case EventTypes.SEND_TRANSACTION:
      const { text, isError } = action.payload as ServiceMessage;
      return { ...state, message: { text, code: isError ? 500 : 0 } };
    case EventTypes.SEND_MESSAGE:
      const messageFromService = action.payload as ServiceMessage;
      return {
        ...state,
        message: {
          text: messageFromService.text,
          code: messageFromService.isError ? 500 : 0,
        },
      };
    case ApplicationActions.SET_MESSAGE:
      const message = action.payload as Message;
      return {
        ...state,
        message: isMessage(message) ? message : EMPTY_MESSAGE,
        isLoading: false
      };
    case EventTypes.COLLECT_TRANSACTION:
      return {
        ...state,
        message: { code: 0, text: (action.payload as Message).text }
      }
    case ApplicationActions.SET_LOADING:
      return { ...state, isLoading: action.payload as boolean };
    default:
      return state;
  }
};
