import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  InboxActions,
  UserActions,
  ApplicationActions,
} from 'src/store/actions';
import { AppState } from 'src/store/models';

import Input, { InputType } from 'src/components/Input';
import Button from 'src/components/Button';
import Instructions from 'src/components/Instructions';
import Page from 'src/layouts/Page';

import { Spacer } from 'src/style';
import TEXT from 'src/data';

export default () => {
  const { isLoading, message, address } = useSelector((state: AppState) => ({
    ...state.app,
    ...state.user,
  }));

  const dispatch = useDispatch();

  const handleAddressChange = (payload: string): void => {
    if (message.text) dispatch({ type: ApplicationActions.SET_MESSAGE });
    dispatch({ type: UserActions.SET_ADDRESS, payload: payload.trim() });
  };

  const handleButtonClick = () =>
    dispatch({ type: InboxActions.HANDLE_CHECK_ADDRESS, payload: address });

  const handleEnterClick = () => address && !isLoading && handleButtonClick();

  return (
    <Page>
      <Instructions instructions={TEXT.welcome.instructions} />
      <Input
        autofocus
        isLoading={isLoading}
        value={address}
        label={{ placeholder: TEXT.welcome.input.placeholder }}
        events={{ onChange: handleAddressChange, onEnter: handleEnterClick }}
        type={InputType.TEXT}
        options={{
          center: true,
          padding: '0.5rem',
          width: '100%',
          maxWidth: '50rem',
        }}
      />
      <Spacer vSpace={2} />
      <Button
        label={TEXT.welcome.input.button}
        labelSize={[1.6]}
        disabled={!address || isLoading}
        onClick={handleButtonClick}
      />
    </Page>
  );
};
