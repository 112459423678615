import React, { ReactElement } from 'react';

import { H5 } from 'src/style';

import { Button, StyleProps, Label } from './Button.style';

export interface Props {
  label: string;
  disabled?: boolean;
  options?: StyleProps;
  labelSize?: number[];
  onClick: () => void;
}

// TODO: Elaborate on options
/**
 * React component to render main button
 * @param {string} label Label to render
 * @param {boolean} disabled Is it disabled?
 * @param {Object} options Options for button - solid, width, margin
 * @param {Array} labelSize Array of options for label - [size, width]
 * @param {()} onClick Function to call when clicked
 * @returns {function} ReactElement
 */
export default ({
  label,
  disabled,
  options,
  labelSize,
  onClick,
}: Props): ReactElement => (
  <Button
    disabled={disabled}
    {...options}
    onClick={(): void | false => !disabled && onClick()}
  >
    {labelSize ? (
      <Label size={labelSize[0]} width={labelSize[1]}>
        {label}
      </Label>
    ) : (
      <H5>{label}</H5>
    )}
  </Button>
);
