import styled from 'styled-components/macro';

import { COLORS, transit } from 'src/style';
import { generateString } from 'src/toolbelt';

interface IconWrapperProps {
  right?: boolean;
  rotate?: boolean;
}

export const IconWrapper = styled.span<IconWrapperProps>`
  ${transit()}
  ${(props: IconWrapperProps): string | null =>
    props.right || props.rotate
      ? generateString('transform', 'rotate(180deg)')
      : null}
  svg {
    margin-inline-end: 0.4rem;
    height: 0.9rem;
  }
`;

interface Props {
  color?: string;
  disabled?: boolean;
  frameless?: boolean;
}

const LineButton = styled.button<Props>`
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  padding: 0.7rem 1.5rem;
  border-radius: 0.3rem;
  border: ${({ frameless }: Props): string =>
    frameless ? 'none' : '0.1rem solid ' + COLORS.white};
  ${({ color, disabled }: Props): string =>
    disabled
      ? generateString('color', COLORS.grey.primary) +
        generateString('border-color', COLORS.grey.secondary)
      : generateString('color', color ? color : COLORS.blue.primary) +
        generateString('border-color', COLORS.white)}
  svg {
    ${({ color, disabled }: Props): string =>
      disabled
        ? generateString('fill', COLORS.grey.primary)
        : generateString('fill', color ? color : COLORS.blue.primary)}
  }
  &:hover {
  ${transit()}
    ${({ disabled }: Props): string =>
      disabled
        ? generateString('color', COLORS.grey.primary) + 'cursor: default;'
        : generateString('color', COLORS.white)}
    ${({ disabled, color }: Props): string =>
      disabled
        ? generateString('background-color', COLORS.white)
        : generateString(
            'background-color',
            color ? color : COLORS.blue.primary,
          )}
    svg {
      ${({ disabled }: Props): string =>
        disabled
          ? generateString('fill', COLORS.grey.primary)
          : generateString('fill', COLORS.white)}
    }
  }
`;

export default LineButton;
