import React from 'react';
import { connect } from 'react-redux';

import { AppState, CollectableExt } from 'src/store/models';

import { P2, COLORS } from 'src/style';

import Confirmations from './Confirmations.style';
import TEXT from 'src/data';

interface Props {
  transaction: CollectableExt;
  height: number;
}

const Component = ({ transaction, height }: Props) => {
  const isConfirmed = transaction.state === 'collected';
  const confirmations = height - transaction.collect.confirmed + 1;

  const getTextColor = () =>
    isConfirmed ? COLORS.green.primary : COLORS.active;

  const confirmationsQty = (qty: number): string =>
    qty > 6 ? '6+' : qty.toString();

  const renderText = () =>
    isConfirmed
      ? `${TEXT.inbox.transaction.confirmations.confirmed} ${confirmationsQty(
          confirmations,
        )}/6`
      : TEXT.inbox.transaction.confirmations.waiting;

  return (
    <Confirmations>
      <P2 color={getTextColor()}>{renderText()}</P2>
    </Confirmations>
  );
};

function mapStateToProps(state: AppState) {
  return { height: state.inbox.tip };
}

export default connect(mapStateToProps)(Component);
