import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import kirobo from './middleware/kirobo'
import reducers from './reducers';
import logger from './middleware/logger';

const isDev = process.env.NODE_ENV === 'development';

const middleware = isDev
  ? applyMiddleware(thunk, kirobo, logger)
  : applyMiddleware(thunk, kirobo);

const store = createStore(reducers, {}, composeWithDevTools(middleware));

export default store;
