import { combineReducers } from 'redux';
import UserReducer from './user';
import InboxReducer from './inbox';
import ApplicationReducer from './app'

export default combineReducers({
  user: UserReducer,
  inbox: InboxReducer,
  app: ApplicationReducer
});
