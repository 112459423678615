import Service, {
  Responses,
  Collectable,
  DebugLevels,
} from '@kiroboio/safe-transfer-lib';

import { ReduxStore } from 'src/process/types';
import {
  InboxActions,
  ApplicationActions,
  UserActions,
} from 'src/store/actions';
import TEXT from 'src/data';
import { validateBtcAddress } from 'src/validators/bitcoin';
import {
  makePath,
  log,
  LogColors,
  makeCollectableObject,
} from 'src/process/tools';

interface Props {
  store: ReduxStore;
}

const isDev = process.env.NODE_ENV==='development'

class Processor {
  private _store: ReduxStore;
  // @ts-ignore
  private _service: Service;

  constructor({ store }: Props) {
    this._store = store;

    // set service options:
    // - we will use Redux' dispatch, not direct replies
    // - assign dispatch() as event bus
    const options = {
      debug: isDev ? DebugLevels.VERBOSE : DebugLevels.MUTE,
      respondAs: Responses.Callback,
      eventBus: this._store.dispatch,
      // TODO: extract below
      authDetails: {
        key: '123',
        secret: '456',
      },
    };
    // initialize service with options
    this._service = this._makeService(options) as Service;
  }

  private _makeService = (options: {}) => {
    try {
      return new Service(options);
    } catch (e) {
      log(LogColors.ERROR, 'Service init', e);
    }
  };

  private _dispatchAction = (
    type: InboxActions | ApplicationActions | UserActions,
  ) => (payload?: any) => this._store.dispatch({ type, payload });
  private _changeModule = this._dispatchAction(
    ApplicationActions.CHANGE_MODULE,
  );
  private _changePath = this._dispatchAction(ApplicationActions.CHANGE_PATH);
  private _loading = this._dispatchAction(ApplicationActions.SET_LOADING);
  private _message = this._dispatchAction(ApplicationActions.SET_MESSAGE);
  private _address = this._dispatchAction(UserActions.SET_ADDRESS);
  private _goHome = this._dispatchAction(InboxActions.HANDLE_RETURN);
  private _loadingById = this._dispatchAction(
    InboxActions.SET_TRANSACTION_LOADING,
  );

  public getSettings = () => this._service.getSettings();

  public getCollectables = (address: string) => {
    if (!address) {
      this._message({ code: 500, text: TEXT.inbox.address.missing });
      this._address('');
      if (window.location.pathname !== '/') this._goHome();
      if (this._store.getState().app.module !== 'welcome')
        this._changeModule('welcome');
      // TODO: move this to library?
    } else if (!validateBtcAddress({ address: address })) {
      // invalid address
      this._message({
        code: 500,
        text: TEXT.inbox.address.invalid,
      });
      if (window.location.pathname !== '/') this._goHome();
    } else {
      // address is present and valid
      if (this._store.getState().user.address !== address)
        this._address(address);
      if (this._store.getState().app.module !== 'inbox')
        this._changeModule('inbox');
      if (!this._store.getState().app.isLoading) this._loading(true);
      const goToPath = makePath(address);
      if (window.location.pathname !== goToPath) this._changePath(goToPath);

      if (process.env.NODE_ENV !== 'test') {
        let interval: number = 0;

        if (!this._checkIfOnline())
          interval = setInterval(() => {
            if (this._checkIfOnline()) {
              this._service.getCollectables([address]);
              clearInterval(interval);
            }
          }, 1500);
        else {
          this._service.getCollectables([address]);
        }
      }
    }
  };

  private _checkIfOnline = () => this._store.getState().inbox.isOnline;

  // TODO: catch it directly in Redux
  public setInbox = (payload: Collectable[]) => {
    this._loading(false);
    this._store.dispatch({
      type: InboxActions.SET_INBOX,
      payload,
    });
  };

  public collect = (id: string, passcode: string) => {
    this._loadingById({ id, status: true });
    const trx = this._store
      .getState()
      .inbox.transactions.filter(trx => trx.id === id)[0];
    console.log(trx)
    console.log(makeCollectableObject(trx, passcode));
    this._service
      .collect(makeCollectableObject(trx, passcode))
      .then((res: { code: number; message: string }): void => {
        this._loadingById({ id, status: false });
      })
      .catch((e: { message: string }): void => {
        this._loadingById({ id, status: false });
        log(LogColors.WARNING, 'collectTrx', e);
      });
  };
}

export default Processor;
