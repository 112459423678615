import React from 'react';
import { Route, useLocation, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { AppState } from 'src/store/models';

import Inbox from 'src/layouts/Inbox';
import Welcome from 'src/layouts/Welcome';
import Forward from './Forward';

import TEXT from 'src/data';
interface Props {
  path: string;
}

export default () => {
  const { path } = useSelector((state: AppState) => ({
    ...state.app,
  }));

  const current = useLocation().pathname;

  return (
    <Switch>
      {!!path && path !== current && <Forward to={path} from={current} />}
      <Route exact path='/'>
        <Welcome />
      </Route>
      <Route path='/inbox'>
        <Inbox />
      </Route>
      <Route
        path='*'
        render={() => <Forward to='/' message={TEXT.navigation[404]} />}
      />
    </Switch>
  );
};
