import { createGlobalStyle } from 'styled-components/macro';
import { DEFAULTS, COLORS } from './defaults';

const Global = createGlobalStyle`
  :root {
    font-size: 62.5%;
    font-family: ${DEFAULTS.ui.font.family.heading};
    color: ${COLORS.black.primary};
  }

  @media (max-width: 399px) {
    font-size: 60%;
  }

  @media (min-width: 651px) {
    font-size: 50%;
  }

  html {
    scroll-behavior: smooth;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    margin: 0;
    height: 100vh;
    width: 100vw;
  }

  * {
    outline: none;
    border: none;
    appearance: none;

    &:active {
      text-decoration: none;
      outline: none;
    }
    &:focus {
      text-decoration: none;
      outline: none;
    }
  }

  /* reset */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  button {
    margin: 0;
    padding: 0;
    font-family: ${DEFAULTS.ui.font.family.heading};
  }

  button {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    appearance: none;

    &:focus {
      outline: none;
    }

    &:active {
      outline: none;
    }
  }
`;

export default Global;
