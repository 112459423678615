import { makeMultiple } from ".";

export enum PeriodUnits {
  SECONDS = 'seconds',
  MINUTES = 'minutes',
  HOURS = 'hours',
  DAYS = 'days',
  AUTO = 'auto',
}

interface MakePeriodProps {
  date: Date;
  units: PeriodUnits;
  brief?: boolean;
}

/**
 * Function to choose the right units, base on the period in seconds
 * @param {number} period Period in seconds
 * @returns PeriodUnits
 */
export const makeUnits = (period: number): PeriodUnits => {
  if (period > 8640) return PeriodUnits.DAYS;
  if (period > 360) return PeriodUnits.HOURS;
  if (period > 60) return PeriodUnits.MINUTES;
  return PeriodUnits.SECONDS;
};

/**
 * Function to make period string
 * @param {Date} date Time period
 * @param {PeriodUnits} units Units for the result
 * @param {boolean} brief Should units be shortened?
 * @returns string
 */
export const makePeriod = ({
  date,
  units,
  brief = false,
}: MakePeriodProps): string => {
  let period = (Date.now() - date.valueOf()) / 1000;
  let periodUnits = makeMultiple(period, 'second', brief);

  const requestedUnits = units === PeriodUnits.AUTO ? makeUnits(period) : units;

  switch (requestedUnits) {
    case PeriodUnits.DAYS:
      period = period / 60 / 60 / 24;
      periodUnits = makeMultiple(period, 'day', brief);
      break;
    case PeriodUnits.HOURS:
      period = period / 60 / 60;
      periodUnits = makeMultiple(period, 'hour', brief);
      break;
    case PeriodUnits.MINUTES:
      period = period / 60;
      periodUnits = makeMultiple(period, 'minute', brief);
      break;
  }

  return `${Math.round(period).toString()} ${periodUnits}`;
};

/**
 * Function to make the string with last update data
 * @param {Date} updated Date, when the data has been updated
 * @returns string
 */
export const makeUpdated = (date: Date): string =>
  `Last updated ${makePeriod({
    date,
    units: PeriodUnits.AUTO,
  })} ago`;
