import {
  keyframes,
  css,
  Keyframes,
  FlattenSimpleInterpolation,
} from 'styled-components/macro';

//  TODO: Refactor
export const menuToWide = keyframes`
 from {
   width: 6rem;
 }
 to {
   width: 15rem;
 }
 `;

export const rotateClock90 = keyframes`
     from {
         transform: none;
     }
     to {
         transform:rotate(90deg);
     }
 `;

export const rotateBackFromClock90 = keyframes`
     from {
         transform:rotate(90deg);
     }
     to {
       transform:none
     }
 `;

export const menuFromWide = keyframes`
 from {
   width: 15rem;
 }
 to {
   width: 6rem;
 }
 `;

//  ! end-of

const slideFromTop = keyframes`
 from {
   transform:translateY(-100%)
 }
 to {
   transform:translateY(0%)
 }
 `;

const slideFromLeft = keyframes`
 from {
   transform:translateX(-100%)
 }
 to {
   transform:translateY(0%)
 }
 `;

const slideToLeft = keyframes`
 from {
   transform:translateX(0%)
 }
 to {
   transform:translateY(-100%)
 }
 `;

const slideFromRight = keyframes`
 from {
   transform:translateX(100%)
 }
 to {
   transform:translateY(0%)
 }
 `;

const appear = keyframes`
 from {
   opacity: 0;
 }
 to {
   opacity: 1;
 }
 `;

const disAppear = keyframes`
 from {
   opacity: 1;
 }
 to {
   opacity: 0;
 }
 `;

const grow = keyframes`
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }`;

export const toFullWidth = keyframes`
  from {
    max-width: 0;
  }
  to {
    max-width: 10rem;
  }
 `;

export const darken = keyframes`
 from {
   background-color: rgba(0,0,0,0);
 }
 to {
   background-color: rgba(0,0,0,0.3);
 }
 `;

export enum Animations {
  SLIDE_FROM_RIGHT = 'slide_from_right',
  SLIDE_FROM_LEFT = 'slide_from_left',
  SLIDE_TO_LEFT = 'slide_from_left',
  SLIDE_FROM_TOP = 'slide_from_top',
  APPEAR = 'appear',
  DISAPPEAR = 'disappear',
  GROW = 'grow',
  TOFULLWIDTH = 'to_full_width',
  DARKEN = 'darken',
  NONE = '',
}

/**
 * Function to generate 'animation:' string
 * @param {Animations} animation Choice of animation
 * @param {Object=} options Optional parameters - duration (default is from settings)
 * @returns FlattenSimpleInterpolation
 */
export const play = (
  animation: Animations,
  options?: { duration?: number | string; times?: string },
): FlattenSimpleInterpolation => {
  let setDuration: number | string = 0.5;
  let setTimes = 'forwards';
  let useAnimation: Keyframes = appear;
  if (options) {
    const { duration, times } = options;
    if (duration) setDuration = duration;
    if (times) setTimes = times;
  }

  switch (animation) {
    case Animations.SLIDE_FROM_LEFT:
      useAnimation = slideFromLeft;
      break;
    case Animations.SLIDE_TO_LEFT:
      useAnimation = slideToLeft;
      break;
    case Animations.SLIDE_FROM_RIGHT:
      useAnimation = slideFromRight;
      break;
    case Animations.SLIDE_FROM_TOP:
      useAnimation = slideFromTop;
      break;
    case Animations.APPEAR:
      useAnimation = appear;
      break;
    case Animations.DISAPPEAR:
      useAnimation = disAppear;
      break;
    case Animations.GROW:
      useAnimation = grow;
      break;
    case Animations.TOFULLWIDTH:
      useAnimation = toFullWidth;
      break;
    case Animations.DARKEN:
      useAnimation = darken;
      break;
  }

  return css`
    animation: ${useAnimation} ${setDuration}s ease-in-out ${setTimes};
  `;
};

/**
 * Function to generation 'transition:' string
 * @param {string} [subject=all] Subject for transition listener
 * @param {Object=} options Optional objects  - duration (default is from settings)
 * @returns FlattenSimpleInterpolation
 */
export const transit = (
  subject = 'all',
  options?: { duration?: number },
): FlattenSimpleInterpolation => {
  const duration = options && options.duration ? options.duration : 0.5;

  return css`
    transition: ${subject} ${duration}s ease-in-out;
  `;
};
