import styled from 'styled-components/macro';
import { FlattenSimpleInterpolation, css } from 'styled-components/macro';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { play, Animations } from 'src/style';

interface Props {
  color: string;
  colorBackground: string;
  to: string;
  wide?: boolean;
  wrapper?: boolean;
  padding?: string;
}

const Flash = styled.div<Props>`
  padding: ${(props: Props): string =>
    props.padding ? props.padding : '1.5rem'};
  box-sizing: border-box;
  ${(props: Props): string | undefined =>
    props.wide ? 'width: inherit;' : undefined}
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ colorBackground }: Props): string => colorBackground};
  border-radius: 0.5rem;
  user-select: none;
  ${(props: Props): FlattenSimpleInterpolation =>
    props.to === 'appear'
      ? css`
          play(Animations.APPEAR)
        `
      : css`
          play(Animations.DISAPPEAR)
        `};
  p {
    color: ${({ color }: Props): string => color};
  }
  svg {
    ${(props: Props): string =>
      props.wrapper
        ? 'height: 2rem; width: 2rem;'
        : 'height: 1.5rem;width: 1.5rem;'}
    margin-inline-end: 1rem;
    path {
      fill: ${({ color }: Props): string => color};
    }
  }
`;

export default Flash;
