import styled from 'styled-components/macro';

import { COLORS, play, Animations } from 'src/style';

export enum TooltipSide {
  left = 'left',
  top = 'top',
  right = 'right',
  bottom = 'bottom',
}

interface TooltipProps {
  side?: TooltipSide;
  width?: number;
}

const sideLocation = (side: TooltipSide, width: number): string => {
  switch (side) {
    case TooltipSide.right:
      return `left: ${width + 1}rem`;
    case TooltipSide.left:
      return `right: ${width + 1}rem`;
    case TooltipSide.bottom:
      return `top: ${width + 1}rem`;
    case TooltipSide.top:
      return `bottom: ${width + 1}rem`;
  }
};

const direction = (side: TooltipSide): string => {
  switch (side) {
    case TooltipSide.right:
      return `row`;
    case TooltipSide.left:
      return `row-reverse`;
    case TooltipSide.bottom:
      return 'column';
    case TooltipSide.top:
      return 'column-reverse';
  }
};

export const TooltipWrapper = styled.div`
  position: absolute;
  height: 0;
  display: flex;
  z-index: 1500;
`;

export const Tooltip = styled.div<TooltipProps>`
  position: relative;
  ${(props: TooltipProps): string | null =>
    props.side && props.width ? sideLocation(props.side, props.width) : null};
  margin: 0;
  display: flex;
  flex-direction: ${(props: TooltipProps): string =>
    props.side ? direction(props.side) : 'row'};
  justify-content: flex-start;
  align-items: center;
  opacity: 0;
  ${play(Animations.APPEAR, { duration: 0.5 })}
`;

const sideRotation = (side: TooltipSide): string => {
  switch (side) {
    case TooltipSide.right:
      return `border-top: 0.7rem solid transparent;
  border-right: 0.7rem solid ${COLORS.black.primary};
  border-bottom: 0.7rem solid transparent;`;
    case TooltipSide.left:
      return `border-top: 0.7rem solid transparent;
  border-left: 0.7rem solid ${COLORS.black.primary};
  border-bottom: 0.7rem solid transparent;`;
    case TooltipSide.bottom:
      return `border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-bottom: 0.7rem solid ${COLORS.black.primary};`;
    case TooltipSide.top:
      return ` border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-top: 0.7rem solid ${COLORS.black.primary};`;
  }
};

interface TriangleProps {
  side?: TooltipSide;
}

export const Triangle = styled.span<TriangleProps>`
  width: 0;
  height: 0;
  ${(props: TriangleProps): string | null =>
    props.side ? sideRotation(props.side) : null};
`;

export const Rectangle = styled.span`
  padding: 1rem 2rem;
  background-color: ${COLORS.black.primary};
  border-radius: 0.5rem;
`;
