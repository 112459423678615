/**
 * Function to make strings of array of strings
 * @param {number|string[]} data Array of strings of numbers to join
 * @return string
 */
export const makeString = (data: (number | string)[]): string => data.join('');

export enum AmountStringTypes {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}
/**
 * Function to convert number into string with decimals and division,
 * with currency string at the end (optional)
 * @param {number} amount Amount
 * @param {string} [currency] Currency string
 * @returns string
 */
export const makeAmount = (
  amount: number,
  units?: string,
  type?: AmountStringTypes,
): string => {
  let amountString = amount.toLocaleString();
  if (!amountString.includes('.')) amountString = `${amountString}.00`;

  const ending = (): string | undefined =>
    type === AmountStringTypes.CURRENCY ? ' ' + units : '%';

  return `${amountString}${units ? ending() : ''}`;
};

/**
 * Function to split words at spaces and join them back with 'joiner' or
 * underscore as default
 * @param {string} words Words string to join
 * @param {string} [joiner='_'] Joining sign, default - underscore
 * @returns string
 */
export const joinWords = (words: string, joiner = '_'): string =>
  words.split(' ').join(joiner);

/**
 * Function to convert number to percentage
 * @param {number} change Number, float
 * @returns string
 */
export const makeChange = (change: number): string => `(${change}%)`;

/**
 * Function to make multiple units names, when needed
 * @param {number} period Period of time
 * @param {string} unit Units in singular form
 * @param {boolean} brief Should be brief or full
 * @returns string
 */
export const makeMultiple = (
  period: number,
  unit: string,
  brief: boolean,
): string => (period < 2 ? (brief ? unit[0] : unit) : `${unit}s`);
