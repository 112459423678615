import styled, { keyframes } from 'styled-components/macro';

const grow = keyframes`
      0%,
  100% {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(2);
  }
 `;

interface Props {
  size?: number;
  margin?: number;
  color?: string;
}

const Loading = styled.div<Props>`
  margin-inline: ${({ margin }: Props): string | undefined =>
    margin + 'rem' || undefined};
  width: ${({ size }: Props): number => size || 2}rem;
  height: ${({ size }: Props): number => size || 2}rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  /* width: 2em; */
  span {
    width: 0.3em;
    height: 1em;
    background-color: ${({ color }: Props): string => color || 'white'};
  }
  span:nth-of-type(1) {
    animation: ${grow} 1s -0.45s ease-in-out infinite;
  }
  span:nth-of-type(2) {
    animation: ${grow} 1s -0.3s ease-in-out infinite;
  }
  span:nth-of-type(3) {
    animation: ${grow} 1s -0.15s ease-in-out infinite;
  }
  span:nth-of-type(4) {
    animation: ${grow} 1s ease-in-out infinite;
  }
`;

export default Loading;
