import React from 'react';

export interface EyeProps {
  options: {
    arc: { path: string; fill?: string; strokeWidth?: string };
    circle: { stroke: string; fill?: string; strokeWidth?: string };
  };
}

/**
 * React component to render SVG icon
 */
export const Eye = ({
  options: { arc, circle },
}: EyeProps): React.ReactElement => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    strokeLinecap='round'
    strokeLinejoin='round'
    strokeMiterlimit='1.5'
    clipRule='evenodd'
    viewBox='0 0 92 40'
  >
    <path
      fill={arc.fill || 'none'}
      stroke={arc.path || 'none'}
      strokeWidth={arc.strokeWidth || '1'}
      d='M9.39 49.648c13.58-8.709 27.272-13.749 41.103-13.559 13.257.182 26.643 5.233 40.071 13.559'
      transform='translate(-4.11 -26.47) matrix(1.09988 0 0 1.41275 -4.969 -23.257)'
    ></path>
    <circle
      cx='49.996'
      cy='28.93'
      r='9.58'
      fill={circle.fill || 'none'}
      stroke={circle.stroke || 'none'}
      strokeWidth={circle.strokeWidth || '1'}
      transform='translate(-4.11 -26.47) translate(-20.631 9.965) scale(1.41275)'
    ></circle>
  </svg>
);

export default Eye;
