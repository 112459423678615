import React from 'react';
import styled from 'styled-components/macro';

import { Line, Spacer, P5 } from 'src/style';
import { Bitcoin } from 'src/assets';

const IconWrapper = styled.span`
  display: flex;
  svg {
    height: 2rem;
    width: 2rem;
  }
`;

interface Props {
  amount: number;
}

export default ({ amount }: Props): React.ReactElement => (
  <Line justify='flex-start' width='auto'>
    <IconWrapper>
      <Bitcoin />
    </IconWrapper>
    <Spacer hSpace={0.5} />
    <P5 nowrap weight={700}>{`tBTC ${(amount / 100000000).toLocaleString()}`}</P5>
  </Line>
);
