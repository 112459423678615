import React from 'react';

import { COLORS, Spacer, H4 } from 'src/style';

import Input, { InputType } from 'src/components/Input';
import LineButton, { LineButtonTypes } from 'src/components/LineButton';

import Slider from './Slider.style';

import TEXT from 'src/data';

interface Props {
  collect: () => void;
  isLoading: boolean;
  isOpen: boolean;
  passcode: string | undefined;
  setPasscode: (arg0: string) => void;
}

export default ({
  collect,
  isLoading,
  isOpen,
  passcode,
  setPasscode,
}: Props): React.ReactElement => (
  <Slider>
    <H4>{TEXT.inbox.collect.passcode}</H4>
    <Spacer hSpace={1} />
    <Input
      autofocus
      showButton
      isLoading={isLoading}
      value={passcode || ''}
      label={{ placeholder: TEXT.inbox.collect.input.placeholder }}
      events={{ onChange: setPasscode, onEnter: collect }}
      type={InputType.PASSWORD}
      options={{
        center: true,
        padding: '0.5rem',
        width: '100%',
        maxWidth: '50rem',
      }}
    />
    <LineButton
      frameless
      disabled={!passcode}
      color={isOpen ? COLORS.blue.primary : COLORS.red.primary}
      label={isOpen ? 'Collect' : TEXT.inbox.transaction.collect}
      type={LineButtonTypes.TEXT}
      onClick={collect}
    />
  </Slider>
);
