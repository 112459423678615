import {
  InboxState,
  INITIAL_INBOX_STATE,
  CollectableExt,
} from 'src/store/models/inbox';
import { InboxActions } from 'src/store/actions';
import { EventTypes } from '@kiroboio/safe-transfer-lib';
import { AppCollectable } from '../../process/types';
import { CollectableTransaction } from 'src/models/collectable.model';
import { updateTrxStateTo } from 'src/toolbelt';

type TransactionState = { id: string; state: string };
type TransactionIsLoading = { id: string; status: boolean };

const updateTipTo = (state: InboxState, tip: number): InboxState =>
  tip !== state.tip ? { ...state, tip } : state;

export default (
  state: InboxState = INITIAL_INBOX_STATE,
  action: {
    type: InboxActions | EventTypes;
    payload?:
      | AppCollectable[]
      | CollectableExt[]
      | CollectableTransaction
      | boolean
      | string
      | Date
      | number
      | TransactionState
      | undefined
      | TransactionIsLoading;
  },
): InboxState => {
  switch (action.type) {
    case EventTypes.CREATED_COLLECTABLE:
      if (action.payload)
        return {
          ...state,
          transactions: [
            ...state.transactions,
            action.payload as CollectableExt,
          ],
        };
      return state;
    case InboxActions.SET_INBOX:
      return {
        ...state,
        transactions: (action.payload as CollectableExt[]) || [],
      };
    case InboxActions.SET_TRANSACTION_LOADING:
      const data = action.payload as TransactionIsLoading;

      return {
        ...state,
        transactions: state.transactions.map(trx => {
          // if matching > update isLoading status to true
          if (trx.id === data.id) trx.isLoading = data.status;
          return trx;
        }),
      };
    case InboxActions.SET_STATUS:
      return { ...state, status: action.payload as string };
    case InboxActions.SET_SELECTED:
      const selectId = action.payload as string;

      return {
        ...state,
        // if ID is in selected list > remove, otherwise > add
        selected: state.selected.includes(selectId)
          ? state.selected.filter(trx => trx !== selectId)
          : [...state.selected, selectId],
      };
    case InboxActions.SET_IS_ONLINE:
      return { ...state, isOnline: action.payload as boolean };
    case EventTypes.UPDATED_COLLECTABLE:
      // eslint-disable-next-line no-case-declarations
      const { isUpdated, trxes } = updateTrxStateTo(
        state.transactions,
        action.payload as CollectableExt,
      );
      return isUpdated ? { ...state, transactions: trxes } : state;
    case EventTypes.REMOVED_COLLECTABLE:
      const removed = action.payload as AppCollectable;
      return {
        ...state,
        transactions: state.transactions.filter(trx => trx.id !== removed.id),
      };
    case InboxActions.SET_TIP:
      return updateTipTo(state, action.payload as number);
    default:
      return state;
  }
};
