import { validateObject } from 'src/validators/data';
import { CollectableExt } from 'src/store/models';

export const updateTrxStateTo = (
  transactions: CollectableExt[],
  newTrx: CollectableExt,
): { isUpdated: boolean; trxes: CollectableExt[] } => {
  let isUpdated = false;

  if (!validateObject(newTrx)) return { isUpdated: false, trxes: transactions };

  // get required field from new
  const {
    id,
    state,
    collect: { confirmed },
  } = newTrx;

  // check each existing for update
  const trxes = transactions.map(trx => {
    if (
      trx.id === id &&
      (trx.state !== state || trx.collect.confirmed !== confirmed)
    ) {
      isUpdated = true;

      return newTrx;
    }
    return trx;
  });

  return { isUpdated, trxes };
};
