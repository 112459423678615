import React from 'react';

/**
 * React component to render SVG icon
 */
export const Exit = (): React.ReactElement => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    clipRule='evenodd'
    viewBox='0 0 512 512'
  >
    <path
      fillRule='nonzero'
      d='M503.942 275.488L333.933 445.497c-15.179 15.179-41.49 4.553-41.49-17.204v-97.147H154.817a24.229 24.229 0 01-24.287-24.287v-97.148a24.229 24.229 0 0124.287-24.287h137.626V88.277c0-21.656 26.21-32.383 41.49-17.204l170.009 170.009c9.411 9.512 9.411 24.894 0 34.406zM195.295 440.437v-40.478c0-6.679-5.464-12.144-12.143-12.144H98.148c-17.912 0-32.383-14.471-32.383-32.382V161.137c0-17.911 14.471-32.382 32.383-32.382h85.004c6.679 0 12.143-5.465 12.143-12.144V76.133c0-6.679-5.464-12.143-12.143-12.143H98.148C44.514 63.99 1 107.504 1 161.137v194.296c0 53.633 43.514 97.147 97.148 97.147h85.004c6.679 0 12.143-5.464 12.143-12.143z'
    ></path>
  </svg>
);
