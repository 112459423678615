import React from 'react';

import { Line, Spacer, P5 } from 'src/style';

interface Props {
  from: string;
}

export default ({ from }: Props): React.ReactElement => (
  <Line justify='flex-start' width='100%'>
    <P5 weight={700}>{`From:`}</P5>
    <Spacer hSpace={0.5} />
    <P5 overflow='hidden'>{from.split(' ').join(' ')}</P5>
  </Line>
);
