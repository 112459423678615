/** Logging tool */

export enum LogColors {
  WARNING = 'background: lightyellow; color: peru; padding: 2px 5px;',
  ERROR = 'background: lightpink; color: darkred; padding: 2px 5px;',
  INFO = 'background: palegreen; color: green; padding: 2px 5px;',
}

/**
 * Function to choose between console.log & console.info
 *
 * @param {string} mode - Mode of display
 * @returns {Function} - Console function
 */
const getConsole = (
  mode: LogColors,
): ((arg0: string, arg1: string, arg2: {} | [] | string) => void) => {
  /* eslint-disable @typescript-eslint/unbound-method */
  if (mode === LogColors.INFO) return console.info;
  if (mode === LogColors.ERROR) return console.error;
  return console.warn;
  /* eslint-enable */
};

const joinString = (string1: string, string2: string): string =>
  `${string1}${string2}`;

/**
 * Function to display message and/or object
 *
 * @param {string} mode - Choice of mode with LogColors enum
 * @param {string}  message  - Message to display - optional
 * @param {object|Array} object - Object or array to display - optional
 */
export const log = (
  mode: LogColors,
  message?: string,
  object?: {} | [],
): void => {
  getConsole(mode)(
    `%c${message ? joinString(' ', message) : ''}`,
    mode,
    object || ' ',
  );
};
