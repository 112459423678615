import { Collectable } from "@kiroboio/safe-transfer-lib";

export interface CollectableExt extends Collectable {
  isLoading?: boolean;
}

export type InboxState = {
  transactions: CollectableExt[];
  status: string;
  selected: string[];
  isOnline: boolean;
  tip: number;
};

export const INITIAL_INBOX_STATE: InboxState = {
  transactions: [],
  status: '',
  selected: [],
  isOnline: false,
  tip: 0,
};
