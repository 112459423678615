import styled from 'styled-components/macro';
import { makeBorder, generateString } from 'src/toolbelt';

interface DividerProps {
  horizontal?: boolean;
  vertical?: boolean;
  width?: number;
  full?: boolean;
  height?: number;
  space?: number;
}

export const Divider = styled.span<DividerProps>`
  ${(props: DividerProps): string | undefined =>
    props.vertical && !props.full
      ? 'width: .1rem;' +
        makeBorder('border-inline-start') +
        generateString('height', props.height ? props.height : 1, 'rem')
      : undefined}
  ${(props: DividerProps): string | undefined =>
    props.vertical && props.space
      ? generateString('margin-left', props.space, 'rem') +
        generateString('margin-right', props.space, 'rem')
      : undefined}
  ${(props: DividerProps): string | undefined =>
    props.horizontal && !props.full
      ? 'height: .2rem;' +
        makeBorder('border-top') +
        generateString('width', props.width ? props.width : 1, 'rem')
      : undefined}
  ${(props: DividerProps): string | false | undefined =>
    (props.horizontal &&
      props.full &&
      'width: inherit;height:.1rem;' + makeBorder('border-top')) ||
    (props.vertical &&
      props.full &&
      'height: inherit;width:.1rem;' + makeBorder('border-inline-start'))}
  ${(props: DividerProps): string | undefined =>
    props.horizontal && props.space
      ? generateString('margin-top', props.space, 'rem') +
        generateString('margin-bottom', props.space, 'rem')
      : undefined}

`;
