import styled from 'styled-components/macro';

import { generateString } from 'src/toolbelt';
import { fontHeading, transit, COLORS, DEFAULTS } from 'src/style';

export interface LabelProps {
  size: number;
  width?: number;
}

export const Label = styled.p<LabelProps>`
  font: ${({ size, width }: LabelProps): string => fontHeading(size, width)};
`;

export interface StyleProps {
  solid?: boolean;
  width?: string;
  margin?: string;
}

export const Button = styled.button<StyleProps>`
  box-sizing: border-box;
  padding: 1.2rem 2rem;
  border-radius: ${DEFAULTS.ui.borderRadius.small};
  border: 1px solid ${COLORS.grey.secondary};
  color: ${({ solid }: StyleProps): string =>
    solid ? COLORS.white : COLORS.menu.primary};
  ${({ width }: StyleProps): string | null =>
    width ? generateString('width', width) : null}
  ${({ margin }: StyleProps): string | null =>
    margin ? generateString('margin', margin) : null}
  ${transit()}
  background-color: ${({ solid }: StyleProps): string =>
    solid ? COLORS.blue.secondary : COLORS.white};
  &:disabled {
    cursor: default;
  }
  &:active:enabled {
    border: 1px solid ${COLORS.blue.primary};
  }
  &:hover:enabled {
    border: 1px solid ${COLORS.blue.transparent[30]};
    background-color: ${({ solid }: StyleProps): string =>
      solid ? COLORS.blue.primary : COLORS.blue.transparent[30]};
    color: ${({ solid }: StyleProps): string =>
      solid ? COLORS.white : COLORS.blue.primary};
  }
`;
