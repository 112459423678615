import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { InboxActions, Action, ApplicationActions } from 'src/store/actions';
import { AppState } from 'src/store/models';

import { Global } from 'src/style';

import Routes from 'src/routes';
import Header from 'src/layouts/Header';
import StatusBar from 'src/components/StatusBar';
import Footer from 'src/layouts/Footer';
import Flash, { FlashTypes } from 'src/components/Flash';

import Message from './App.style';

import TEXT from 'src/data';

export default (): React.ReactElement => {
  const { message, address, status, isInbox } = useSelector(
    (state: AppState) => ({
      ...state.app,
      ...state.inbox,
      ...state.user,
      isInbox: state.app.module === 'inbox',
    }),
  );

  const dispatch = useDispatch();

  const handleClearMessage = (): Action =>
    dispatch({ type: ApplicationActions.SET_MESSAGE });

  const handleReturn = () => dispatch({ type: InboxActions.HANDLE_RETURN });

  const handleDisplayCopyConfirmation = () =>
    dispatch({
      type: ApplicationActions.SET_MESSAGE,
      payload: { code: 10, text: TEXT.header.addressCopied },
    });

  const getTitle = (): string =>
    isInbox && !!address ? TEXT.inbox.title : TEXT.welcome.title;

  const getSubtitle = (): string =>
    isInbox && !!address ? address : TEXT.welcome.subtitle;

  const getFlashStyle = () => {
    if (message.code === 500) return FlashTypes.WARNING;
    if (message.code === 10) return FlashTypes.INFO;
    return FlashTypes.CONFIRMATION;
  };

  return (
    <Router>
      <Global />
      <Header
        isInbox={isInbox}
        address={address}
        title={getTitle()}
        subtitle={getSubtitle()}
        onClick={handleReturn}
        displayCopyConfirmation={handleDisplayCopyConfirmation}
      />
      <Routes />
      <Footer>
        <StatusBar status={status} />
      </Footer>
      <Message isOn={!!message.text}>
        <Flash
          type={getFlashStyle()}
          message={message.text}
          removeMe={handleClearMessage}
        />
      </Message>
    </Router>
  );
};
