import React from 'react';
import { P5 } from 'src/style';
import SubTitle from './SubTitle.style';
import SpringString from 'src/components/SpringString';

interface Props {
  opposite: boolean;
  subtitle: string;
  onClick: () => void;
}

export default ({ opposite, subtitle, onClick }: Props) => (
  <SubTitle>
    {opposite ? (
      <SpringString
        clickToCopy
        onClick={onClick}
        data={subtitle}
        options={{
          center: true,
          fontWeight: '700',
          fontSize: '2.3rem',
          cursor: 'pointer',
        }}
      />
    ) : (
      <P5 textAlign='center'>{subtitle}</P5>
    )}
  </SubTitle>
);
