import styled from 'styled-components/macro';

const Title = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  align-content: center;
`;

export default Title;
