import React from 'react';
import { Row } from './Card.style';
import Collect from 'src/components/Collect';
import State from 'src/components/State';
import Confirmations from 'src/components/Confirmations';
import { Spacer } from 'src/style';
import { CollectableExt } from 'src/store/models';

interface Props {
  isOpen: boolean;
  transaction: CollectableExt;
  toggleOpen: () => void;
}

export default ({ isOpen, toggleOpen, transaction }: Props) => {
  const isCollectable =
    transaction.state !== 'collected' && transaction.state !== 'collecting';
  const isBusy = transaction.isLoading || transaction.state !== 'ready';
  return (
    <Row alignEnd>
      {transaction.state !== 'ready' && (
        <>
          <State state={transaction.state} />
          <Spacer hSpace={2} />
        </>
      )}
      {!isCollectable && <Confirmations transaction={transaction} />}
      {isCollectable && (
        <Collect disabled={isBusy} isOpen={isOpen} onClick={toggleOpen} />
      )}
    </Row>
  );
};
