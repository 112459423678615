import styled from 'styled-components/macro';

const Confirmations = styled.div`
  display: flex;
  flex-direction: row nowrap;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  text-align: right;
  user-select: none;
  @media (max-width: 870px) {
    white-space: pre-wrap;
  }
`;

export default Confirmations;
