import { COLORS } from 'src/style';

export const generateString = (
  key: string,
  value: string | number,
  units = '',
): string => `${key}:${!!key && value}${units};`;

export const makeBorder = (side: string): string =>
  `${side}:.1rem solid ${COLORS.grey.secondary};`;
