import styled from 'styled-components/macro';

const Page = styled.main`
  margin: 0 auto;
  padding: 2rem 2rem 10rem 2rem;
  display: flex;
  flex-flow: column;
  width: 90%;
  max-width: 90rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 680px) {
    width: 95%;
    padding: 2.5%;
  }
  @media (max-width: 644px) {
    width: 98%;
    padding: 2.5% 1% 10rem 1%;
  }
`;

export default Page;
