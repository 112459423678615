/** Status maker */

import { NetworkTip } from 'src/process/types';

const makeMonth = (month: number): string =>
  [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ][month];

const increaseDigits = (data: number): string | number =>
  data < 10 ? `0${data}` : data;

// TODO: Move general tools out?
const makeDateTimeString = (data: Date): string =>
  `${makeMonth(
    data.getMonth(),
  )} ${data.getDate()}, ${data.getFullYear()} at ${data.getHours()}:${increaseDigits(
    data.getMinutes(),
  )}`;

// const makeDate = (data: Date): Date => new Date(adjustTimestamp(data));

const isOnline = (status: boolean): string => (status ? 'online' : 'offline');

// TODO: add desc
// TODO: refactor
export default (status: NetworkTip): string =>
  `Last update: ${makeDateTimeString(status.timestamp)}, API is ${isOnline(
    status.online,
  )}, block: ${status.height}`;
