import React from 'react';

import { P2 } from 'src/style';
import { Address, AddressTitle } from './Address.style';

import TEXT from 'src/data';

interface Props {
  address: string;
}
export default ({ address }: Props) => (
  <Address>
    {(!!address && (
      <P2>
        <AddressTitle>{`${TEXT.header.return}`}</AddressTitle>
      </P2>
    )) ||
      ''}
  </Address>
);
