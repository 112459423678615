import React from 'react';

import { COLORS, P6, Spacer, Line } from 'src/style';
import { Info } from 'src/icons';

import Instructions from './Instructions.style';

interface Props {
  instructions: { title: string; message: string };
}

export default ({
  instructions: { title, message },
}: Props): React.ReactElement => (
  <Instructions>
    <Line width='auto'>
      <Info />
      <P6 color={COLORS.black.primary}>{title}</P6>
    </Line>
    <Spacer vSpace={0.7} />
    <Line width='auto'>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <P6>{message}</P6>
      </div>
    </Line>
  </Instructions>
);
