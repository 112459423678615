import styled from 'styled-components/macro';

import { transit } from 'src/style';

const Slider = styled.div`
  height: 5rem;
  width: 80%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding: 0.2rem 2rem;
  z-index: 1;
  background-color: white;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  ${transit()}
  input {
    font-size: 1.6rem;
    &::placeholder {
      font-size: 1.6rem;
    }
    width: 100%;
  }
  button {
    margin-inline-start: 1rem;
  }
  @media (max-width: 600px) {
    h4 {
      display: none;
    }
  }
  @media (max-width: 540px) {
    padding: 1rem 0.5rem;
    width: 95%;
    button {
      margin-inline-start: 1rem;
    }
  }
`;

export default Slider;
