/** Validators for Bitcoin-related data */
import validator from 'multicoin-address-validator';
import { isString } from 'src/process/tools';

interface Props {
  address: string;
  currency?: string;
  networkType?: string;
}

export const validateBtcAddress = ({
  address,
  currency,
  networkType,
}: Props): boolean =>
  isString(address)
    ? validator.validate(
        address,
        currency || 'bitcoin',
        networkType || 'testnet',
      )
    : false;
