import styled from 'styled-components/macro';

interface Props {
  background?: string;
  backgroundColor?: string;
  center?: boolean;
  color?: string;
  cursor?: string;
  font?: string;
  fontSize?: string;
  fontWeight?: string;
  maxWidth?: string;
  minWidth?: string;
  select?: boolean;
  width?: string;
}

export const Container = styled.div<Props>`
         display: block;
         white-space: nowrap;
         overflow:hidden;
           ${({ width }: Props): string =>
             width ? 'width: ' + width + ';' : 'width: 100%;'}
           ${({ minWidth }: Props): string | undefined =>
             minWidth ? 'min-width: ' + minWidth + ';' : undefined}
           ${({ maxWidth }: Props): string | undefined =>
             maxWidth ? 'max-width: ' + maxWidth + ';' : undefined}
           ${({ background }: Props): string | undefined =>
             background ? 'background: ' + background + ';' : undefined}
           ${({ fontSize }: Props): string | undefined =>
             fontSize ? 'font-size: ' + fontSize + ';' : undefined}
           ${({ fontWeight }: Props): string | undefined =>
             fontWeight ? 'font-weight: ' + fontWeight + ';' : undefined}
           ${({ font }: Props): string | undefined =>
             font ? 'font: ' + font + ';' : undefined}
           ${({ color }: Props): string | undefined =>
             color ? 'color: ' + color + ';' : undefined}
           ${({ select }: Props): string =>
             select ? 'user-select:auto;' : 'user-select:none;'}
           ${({ cursor }: Props): string | undefined =>
             cursor ? 'cursor: ' + cursor + ';' : undefined}
           ${({ backgroundColor }: Props): string | undefined =>
             backgroundColor
               ? 'background-color: ' + backgroundColor + ';'
               : undefined};
       `;

export const LongText = styled.span<Props>`
  display: flex;
  flex-shrink: 1 1 auto;
  width: inherit;
  overflow-x: hidden;
  ${({ center }: Props): string | undefined =>
    center ? 'margin: 0 auto;' : undefined}
`;

export const Left = styled.span`
  display: flex;
  justify-content: flex-start;
  overflow-x: hidden;
  width: fit-content;
`;

export const Full = styled(Left)<Props>`
  ${({ center }: Props): string | undefined =>
    center ? 'margin: 0 auto;' : undefined}
`;

export const Right = styled(Left)`
  justify-content: flex-end;
`;
export const Divider = styled.span``;
