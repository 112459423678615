import { COLORS } from "src/style/defaults";

export const shadows = {
  flat: `0 0 1rem ${COLORS.black.transparent[15]}`,
  deep: `box-shadow: 0 0 .5rem ${COLORS.black.transparent[30]}`,
  deeper: `0 0 1rem .5rem ${COLORS.black.transparent[15]}`,
  card: `0 0 1rem ${COLORS.black.transparent[30]}`,
  text: {
    prominent: `0 0.1rem 0.3rem ${COLORS.black.transparent[40]}`,
  },
};
