import React, { ReactElement } from 'react';

import { Line, P1 } from 'src/style';
import LineButton, { IconWrapper } from './LineButton.style';

export enum LineButtonTypes {
  TEXT = 'text',
  TEXT_WITH_ICON_LEFT = 'text_with_icon_left',
  TEXT_WITH_ICON_RIGHT = 'text_with_icon_right',
  ICON = 'icon',
}

export interface LineButtonProps {
  frameless?: boolean;
  disabled?: boolean;
  label: string;
  icon?: JSX.Element;
  color?: string;
  rotateIcon?: boolean;
  type: LineButtonTypes;
  onClick: () => void;
}

/**
 * React component to render line (text) buttons with icons, without
 * icons and icons only
 * @param {string} label Label of the button, required even if it's
 * icon-only button
 * @param {JSX.Element} [icon] Icon
 * @param {string} [color] Color of the icon/text
 * @param {boolean} [rotateIcon] Should the icon be rotated (needed only for
 * icon-only button)
 * @param {LineButtonTypes} type Enum selection of types
 * @param {()} onClick Function to call on click event
 * @returns ReactElement
 */
export default ({
  disabled,
  frameless,
  label,
  icon,
  color,
  rotateIcon,
  type,
  onClick,
}: LineButtonProps): ReactElement => {
  let content: JSX.Element = <P1 size={1.6}>{label}</P1>;

  const renderIcon = (right?: boolean): JSX.Element => (
    <IconWrapper
      data-testid='card__text-button_icon'
      right={right}
      rotate={rotateIcon}
    >
      {icon}
    </IconWrapper>
  );

  switch (type) {
    case LineButtonTypes.TEXT_WITH_ICON_LEFT:
      content = (
        <Line data-testid='card__text-button_label'>
          {renderIcon()}
          {content}
        </Line>
      );
      break;
    case LineButtonTypes.TEXT_WITH_ICON_RIGHT:
      content = (
        <Line data-testid='card__text-button_label'>
          {content}
          {renderIcon(true)}
        </Line>
      );
      break;
    case LineButtonTypes.ICON:
      content = icon ? (
        renderIcon()
      ) : (
        <Line data-testid='card__text-button_label'>{label}</Line>
      );
  }

  return (
    <LineButton
      frameless={frameless}
      disabled={disabled}
      data-testid='card__text-button'
      color={color}
      onClick={(): void | false => !disabled && onClick()}
    >
      {content}
    </LineButton>
  );
};
