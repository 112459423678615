import React from 'react';

import { Line, Spacer } from 'src/style';

import Amount from 'src/components/Amount';
import From from 'src/components/From';

import { Row } from './Card.style';

interface Props {
  from?: string;
  amount: number;
}

export default ({from, amount }: Props) => (
  <Row>
    <Line width='100%' justify='space-between'>
      <Amount amount={amount} />
    </Line>
    {from && (
      <>
        <From from={from} />
        <Spacer hSpace={2} />
      </>
    )}
  </Row>
);
