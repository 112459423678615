import React from 'react';

import { COLORS, Line } from 'src/style';

import LineButton, { LineButtonTypes } from 'src/components/LineButton';

import TEXT from 'src/data';

interface Props {
  disabled?: boolean;
  isOpen: boolean;
  onClick: () => void;
}

export default ({ disabled, isOpen, onClick }: Props): React.ReactElement => (
  <Line justify='space-between' width='auto'>
    <div style={{ fontSize: '1.6rem !important' }}>
      <LineButton
        disabled={disabled}
        color={isOpen ? COLORS.red.primary : COLORS.blue.primary}
        label={isOpen ? TEXT.inbox.transaction.cancel : TEXT.inbox.transaction.collect}
        type={LineButtonTypes.TEXT}
        onClick={onClick}
      />
    </div>
  </Line>
);
