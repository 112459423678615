import React from 'react';

/**
 * React component to render Bitcoin logo
 * @returns JSX.Element
 */
export default (): JSX.Element => (
  <svg xmlns='http://www.w3.org/2000/svg'
    width='64'
    height='64'
    preserveAspectRatio='xMidYMid'
    viewBox='0 0 64 64'>
    <path
      fill='#f7931a'
      d='M63.033 39.744C58.759 56.887 41.396 67.32 24.251 63.045 7.113 58.771-3.32 41.407.956 24.265 5.228 7.12 22.591-3.314 39.731.96 56.875 5.234 67.307 22.6 63.033 39.744z'
      transform='translate(.006 -.003)'
    ></path>
    <path
      fill='#FFF'
      d='M46.103 27.444c.637-4.258-2.605-6.547-7.038-8.074l1.438-5.768-3.511-.875-1.4 5.616c-.923-.23-1.871-.447-2.813-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.679 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.371-.092-2.296 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.019 4.569 1.139c.85.213 1.683.436 2.503.646l-1.453 5.834 3.507.875 1.439-5.772c.958.26 1.888.5 2.798.726l-1.434 5.745 3.511.875 1.453-5.823c5.987 1.133 10.489.676 12.384-4.739 1.527-4.36-.076-6.875-3.226-8.515 2.294-.529 4.022-2.038 4.483-5.155zm-8.022 11.249c-1.085 4.36-8.426 2.003-10.806 1.412l1.928-7.729c2.38.594 10.012 1.77 8.878 6.317zm1.086-11.312c-.99 3.966-7.1 1.951-9.082 1.457l1.748-7.01c1.982.494 8.365 1.416 7.334 5.553z'
      transform='translate(.006 -.003)'
    ></path>
  </svg>
);
