// import { COLORS } from './index/colors';

type IndexedObject = { [index: string]: any };

// TODO: Should it remain here?
export const COLORS: IndexedObject = {
  black: {
    primary: '#444',
    transparent: {
      15: 'rgba(68,68,68,.15)',
      30: 'rgba(68,68,68,.3)',
      40: 'rgba(68,68,68,.3)',
    },
  },
  white: '#ffffff',
  active: '#ffd04b', // active menu
  menu: { primary: '#545c64', secondary: 'rgb(204,204,204)' },
  grey: { primary: '#919191', secondary: '#e7e7e7' },
  green: { primary: '#268e1e', secondary: '#7fe25a' },
  red: { primary: '#f0050f', secondary: '#ff8080' },
  blue: {
    primary: '#1C7DD5',
    secondary: '#67ABF1',
    transparent: {
      30: 'rgba(103, 171, 241,.3)',
    },
  },
  kirobo: '#1a2c43',
};

export const DEFAULTS: IndexedObject = {
  card: {
    dashboard: {
      height: { closed: '13.3rem', opened: '34rem' },
      padding: { opened: '2rem', closed: '2rem' },
    },
    regular: {
      height: { closed: '24rem', opened: 'fit-content' },
      padding: {
        closed: '2rem',
        opened: '2rem',
      },
    },
  },
  chart: {
    colorSet: [
      COLORS.blue.primary,
      COLORS.red.primary,
      COLORS.green.primary,
      COLORS.grey.primary,
    ],
  },
  ui: {
    letterSpacing: '.03rem',
    borderRadius: {
      regular: '0.5rem',
      small: '.3rem',
      large: '1rem',
    },
    font: {
      family: {
        heading: 'Roboto Condensed, Arial Narrow, sans-serif',
        text: 'Lato, Arial, sans-serif',
      },
    },
  },
};
