import styled from 'styled-components/macro';

import { COLORS, shadows } from 'src/style';

export const ContainerLogo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: auto;
  grid-area: logo;

  @media (max-width: 799px) {
    height: 4rem;
    justify-content: center;
    svg {
      height: 2.5rem !important;
    }
  }
`;
export const ContainerRectangle = styled.div`
  display: block;
  grid-area: rectangle;
`;
export const ContainerExit = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  width: auto;
  justify-content: flex-end;
  grid-area: exit;
  @media (max-width: 799px) {
    justify-content: center;
  }
`;

interface Props {
  inbox: boolean;
}

const Header = styled.header<Props>`
  position: relative;
  padding: 1rem 2rem;
  background-color: ${COLORS.white};
  box-shadow: ${shadows.flat};
  z-index: 100;
  min-height: 4.8rem;
  display: grid;
  grid-template-columns: 15% 70% 15%;
  grid-template-areas: 'logo rectangle exit';

  @media (max-width: 799px) {
    grid-template-columns: 100%;
    grid-template-areas:
      'logo'
      'rectangle'
      'exit';
  }
`;

export default Header;
