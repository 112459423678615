import React from 'react';
import styled from 'styled-components/macro';
import { DEFAULTS } from 'src/style/defaults';
import { generateString } from 'src/toolbelt';

const letterSpacing = DEFAULTS.ui.letterSpacing;

interface TypographyOptions {
  color?: string;
  spaced?: boolean;
  message?: boolean;
  margin?: string;
  weight?: number;
  size?: number;
  type?: string;
  family?: string;
  textAlign?: string;
  nowrap?: boolean;
  overflow?: string;
}

const Default = styled.h1<TypographyOptions>`
  font-size: ${({ size }: TypographyOptions): number => (size ? size : 2.5)}rem;
  font-weight: ${({ weight, message }: TypographyOptions): number =>
    weight ? weight : message ? 400 : 700};
  ${({ spaced }: TypographyOptions): string | null =>
    spaced
      ? 'letter-spacing:' + letterSpacing + 'rem;'
      : 'letter-spacing:.01rem;'}
  ${({ color }: TypographyOptions): string | null =>
    color ? 'color:' + color + ';' : null}
  ${({ message, type }: TypographyOptions): null | string =>
    message ? null : 'text-transform:capitalize;'}
  ${({ margin, type }: TypographyOptions): string | null =>
    margin ? 'margin:' + margin + ';' : null}
  ${({ family }: TypographyOptions): string | null =>
    family ? 'font-family:' + family + ';' : null}
  ${({ textAlign }: TypographyOptions): string | null =>
    textAlign ? 'text-align:' + textAlign + ';' : null}
  ${({ nowrap }: TypographyOptions): string | null =>
    nowrap ? 'white-space: nowrap;' : null}
  ${({ overflow }: TypographyOptions): string | null =>
    overflow ? generateString('overflow', overflow) : null}
   `;

interface TypographyProps extends TypographyOptions {
  children: JSX.Element | string | number | undefined;
}

export const H1 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default as='h1' {...props}>
    {children}
  </Default>
);

export const H2 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default as='h2' size={2.3} {...props}>
    {children}
  </Default>
);

export const H3 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default as='h3' size={1.9} {...props}>
    {children}
  </Default>
);

export const H4 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default as='h4' size={1.6} {...props}>
    {children}
  </Default>
);

export const H5 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default as='h5' size={1.4} {...props}>
    {children}
  </Default>
);

export const H6 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default as='h6' size={1.5} {...props}>
    {children}
  </Default>
);

export const P1 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default message as='p' size={1.3} {...props}>
    {children}
  </Default>
);

export const P2 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default message as='p' size={1.5} {...props}>
    {children}
  </Default>
);

export const P3 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default message as='p' size={1.1} {...props}>
    {children}
  </Default>
);

export const P4 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default message as='p' size={1.4} {...props}>
    {children}
  </Default>
);

export const P5 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default message as='p' size={1.6} {...props}>
    {children}
  </Default>
);

export const P6 = ({ children, ...props }: TypographyProps): JSX.Element => (
  <Default message as='p' size={1.8} {...props}>
    {children}
  </Default>
);

export const fontHeading = (size: number, weight?: number): string =>
  `normal ${weight || 400} ${size}rem ${DEFAULTS.ui.font.family.heading}`;

export const fontText = (size: number, weight?: number): string =>
  `normal ${weight || 400} ${size}rem ${DEFAULTS.ui.font.family.text}`;
