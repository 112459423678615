import React from 'react';
import { Divider } from 'src/style';
import { Exit } from 'src/icons';

import Header, {
  ContainerLogo,
  ContainerExit,
  ContainerRectangle,
} from './Header.style';
import Title from 'src/components/Title';
import Address from 'src/components/Address';
import Button from 'src/hocs/Clickable';
import { Logo } from 'src/assets';
import SubTitle from 'src/components/SubTitle';
import ExitWrapper from 'src/hocs/Exit';

interface Props {
  address: string;
  isInbox: boolean;
  title: string;
  subtitle: string;
  onClick: () => void;
  displayCopyConfirmation: () => void;
}

export default ({
  isInbox,
  address,
  title,
  subtitle,
  onClick,
  displayCopyConfirmation,
}: Props): React.ReactElement => (
  <Header inbox={isInbox}>
    <ContainerLogo>
      <Logo />
    </ContainerLogo>
    <ContainerRectangle>
      <Title opposite={isInbox} title={title} />
      <SubTitle
        opposite={isInbox}
        subtitle={subtitle}
        onClick={displayCopyConfirmation}
      />
    </ContainerRectangle>
    <ContainerExit>
      {!!address && isInbox && (
        <ExitWrapper>
          <Address address={address} />
          <Divider vertical height={3} space={1} />
          <Button onClick={onClick} size={2}>
            <Exit />
          </Button>
        </ExitWrapper>
      )}
    </ContainerExit>
  </Header>
);
