import styled from 'styled-components/macro';

import { COLORS } from 'src/style';

const Instructions = styled.section`
  /* width: 70%; */
  /* max-width: 40rem; */
  padding: 2rem;
  margin: 2rem 0;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${COLORS.grey.primary};
  background-color: ${COLORS.blue.transparent[30]};
  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-inline-end: 1rem;
    path {
      fill: ${COLORS.black.primary};
    }
  }
  @media (max-width: 620px) {
    /* width: 80%; */
  }
  @media (max-width: 450px) {
    /* width: inherit; */
  }
`;

export default Instructions;
