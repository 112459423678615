import React from 'react'

export default () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    clipRule='evenodd'
    viewBox='0 0 286 95'
    style={{height: '3.5rem',marginTop:"-1rem"}}
  >
    <g transform='translate(-2 -2)'>
      <path
        fill='#751C4B'
        fillRule='nonzero'
        d='M244.1 44.6h-21.9l-11 19 11 19h21.9l11-19-11-19z'
      ></path>
      <path
        fill='#EE499A'
        fillRule='nonzero'
        d='M255.1 63.6l-11-19h-21.9l10.9 19h22z'
      ></path>
      <path
        fill='#DB207A'
        fillRule='nonzero'
        d='M222.2 44.6l-11 19 11 19 10.9-19-10.9-19z'
      ></path>
      <g fill='#415364' fillRule='nonzero'>
        <path d='M246.8 2L225 39.7h10.9L257.7 2h-10.9zM268.7 2h-5.5l-21.7 37.7h5.5l1.3 2.3 1.4 2.4 21.7-37.7-2.7-4.7zM274.2 11.5l-21.7 37.7 5.4 9.5L279.7 21l-5.5-9.5z'></path>
        <path d='M282.4 25.8l-35.5 61.6h-27.5l-13.7-23.8 23.5-40.7h-10.9l-23.5 40.7L214 96.8h38.3l35.6-61.6-5.5-9.4z'></path>
      </g>
      <path fill='#415364' d='M2 35.8H11.5V96.1H2z'></path>
      <path
        fill='#415364'
        fillRule='nonzero'
        d='M9.3 61.9h.1l15.3-26.1h9.5L18 62.7l18.2 33.4h-9.8L9.2 66.9'
      ></path>
      <path fill='none' d='M13.3 60.2v1.7'></path>
      <path
        fill='#415364'
        fillRule='nonzero'
        d='M39.5 35.8H49V96.1H39.5z'
      ></path>
      <path
        fill='#415364'
        fillRule='nonzero'
        d='M74.8 96.1V79.2c0-5.6-1.9-7.7-6.2-7.7h-3.3v24.6h-9.5V35.8h14.3c9.8 0 14 4.6 14 13.9v4.7c0 6.2-2 10.2-6.2 12.1v.2c4.7 2 6.3 6.5 6.3 12.8v16.6h-9.4zm-9.5-51.7V63H69c3.5 0 5.7-1.6 5.7-6.4v-5.9c0-4.3-1.5-6.2-4.8-6.2h-4.6v-.1zM89.7 50.3c0-9.7 5.1-15.2 14.4-15.2s14.4 5.5 14.4 15.2v31.4c0 9.7-5.1 15.2-14.4 15.2s-14.4-5.5-14.4-15.2V50.3zm9.5 32c0 4.3 1.9 5.9 4.9 5.9 3 0 4.9-1.6 4.9-5.9V49.7c0-4.3-1.9-5.9-4.9-5.9-3 0-4.9 1.6-4.9 5.9v32.6zM153 49.7v2.2c0 6.2-1.9 10.1-6.1 12.1v.2c5.1 2 7.1 6.5 7.1 12.8v4.9c0 9.3-4.9 14.3-14.4 14.3h-14.9V35.8H139c9.7 0 14 4.6 14 13.9zm-18.9-5.3v15.9h3.7c3.5 0 5.7-1.6 5.7-6.4v-3.4c0-4.3-1.5-6.2-4.8-6.2h-4.6v.1zm0 24.6v18.5h5.4c3.2 0 4.9-1.5 4.9-5.9v-5.3c0-5.6-1.8-7.3-6.1-7.3h-4.2zM158.8 50.3c0-9.7 5.1-15.2 14.4-15.2s14.4 5.5 14.4 15.2v31.4c0 9.7-5.1 15.2-14.4 15.2s-14.4-5.5-14.4-15.2V50.3zm9.5 32c0 4.3 1.9 5.9 4.9 5.9 3 0 4.9-1.6 4.9-5.9V49.7c0-4.3-1.9-5.9-4.9-5.9-3 0-4.9 1.6-4.9 5.9v32.6z'
      ></path>
    </g>
  </svg>
);