import styled from 'styled-components/macro';

import { COLORS, transit } from 'src/style';

interface Props {
  alignEnd?: boolean;
}
export const Row = styled.div<Props>`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: ${({ alignEnd }: Props): string =>
    alignEnd ? 'flex-end' : 'flex-start'};
  flex: auto;
  ${({ alignEnd }: Props): string | undefined =>
    alignEnd ? 'margin-inline-start: 1rem;' : undefined}

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

export const Card = styled.div`
  z-index: 2;
  width: 90%;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
  border: 1px solid white;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  ${transit()}
  &:last-of-type {
    margin-bottom: 0;
  }
  &:hover {
    /* border: 1px solid rgb(219,32,122); */
    border: 1px solid ${COLORS.blue.secondary};
    box-shadow: 0 0.1rem 1.5rem ${COLORS.black.transparent[30]};
  }
  @media (max-width: 745px) {
    padding: 1rem;
    width: 95%;
    div {
      & > *:nth-of-type(1) {
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: 600px) {
    & > *:nth-child(1) {
      flex-flow: column;
      & > *:nth-child(1) {
        margin-bottom: 1rem;
      }
      & > *:nth-child(3) {
        div {
          width: auto;
        }
        justify-content: flex-start;
      }
    }
  }
  @media (max-width: 425px) {
    flex-flow: column;
  }
`;

const Container = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
  ${transit()}
`;

export default Container;
