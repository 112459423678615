import styled from 'styled-components/macro';

const SubTitle = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: 600px) {
    h2 {
      font-size: 2rem !important;
    }
  }

  @media (max-width: 500px) {
    h2 {
      font-size: 1.8rem !important;
    }
  }

  @media (max-width: 400px) {
    h2 {
      font-size: 1.6rem !important;
    }
  }
`;

export default SubTitle;
