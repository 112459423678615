import styled from 'styled-components/macro';
import { generateString } from 'src/toolbelt';

interface LineProps {
  align?: string;
  height?: string;
  justify?: string;
  width?: string;
  wrap?: string;
  marginVert?: number;
}

export const Line = styled.div<LineProps>`
  display: flex;
  flex-flow: row nowrap;
  ${({ marginVert }: LineProps): string | null =>
    marginVert ? generateString('margin-top', marginVert, 'rem') : null}
     ${({ marginVert }: LineProps): string | null =>
       marginVert ? generateString('margin-bottom', marginVert, 'rem') : null}
  ${({ wrap }: LineProps): string | null => (wrap ? 'flex-wrap: wrap;' : null)}
  ${(props: LineProps): string =>
    generateString(
      'justify-content',
      props.justify ? props.justify : 'space-between',
    )}
  ${(props: LineProps): string =>
    generateString('width', props.width ? props.width : 'inherit')}
  ${(props: LineProps): string =>
    generateString('align-items', props.align ? props.align : 'center')}
  ${(props: LineProps): string | undefined =>
    props.height ? generateString('height', props.height) : undefined};
  ${(props: LineProps): string | undefined =>
    props.height ? generateString('height', props.height) : undefined};
`;
