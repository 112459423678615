export const makePath = (address: string): string => `/inbox/${address}`;

export const isString = (data: unknown) => typeof data === 'string';

// TODO: add desc
const splitText = (text: string): string[] => text.split('');

// TODO: add dec
const reassign = (
  group: string[],
  position: number,
  newMember: string,
): string[] => {
  group.splice(0, 1);
  return [newMember, ...group];
};

export const capitalize = (text: string): string =>
  reassign(splitText(text), 0, splitText(text)[0].toUpperCase()).join('');