import React, { useEffect, createRef, useState } from 'react';

import {
  Container,
  LongText,
  Left,
  Right,
  Divider,
  Full,
} from './SpringString.style';

interface Props {
  clickToCopy?: boolean;
  data: string;
  dividedBy?: string;
  onClick?: () => void;
  onClickGetData?: (arg0: string) => void;
  options?: {
    background?: string;
    backgroundColor?: string;
    center?: boolean;
    color?: string;
    cursor?: string;
    font?: string;
    fontSize?: string;
    fontWeight?: string;
    maxWidth?: string;
    minWidth?: string;
    select?: boolean;
    width?: string;
  };
}

export default ({
  clickToCopy,
  data,
  dividedBy,
  onClick,
  onClickGetData,
  options,
}: Props): React.ReactElement => {
  // set the refs
  const containerRef = createRef<HTMLDivElement>();
  const longTextRef = createRef<HTMLDivElement>();
  const shortTextRef = createRef<HTMLDivElement>();
  // set variables
  const [dataLength, setDataLength] = useState(0);
  const [springMode, setSpringMode] = useState(false);
  const [windowHasUpdated, setWindowHasUpdated] = useState();

  // add/remove event listeners on window changes
  useEffect(() => {
    window.addEventListener('resize', (event: any) => {
      setWindowHasUpdated(event);
    });
    window.addEventListener('orientationchange', (event: any) => {
      setWindowHasUpdated(event);
    });

    // on dismantling -> remove event listeners
    return () => {
      window.removeEventListener('resize', () => {});
      window.removeEventListener('orientationchange', () => {});
    };
  }, [containerRef, shortTextRef, longTextRef]);

  // main logic
  useEffect(() => {
    // get lengths
    const shortTextLength = shortTextRef.current?.offsetWidth || 0;
    const containerLength = containerRef.current?.offsetWidth || 0;
    // set dataLength is need to
    if (shortTextLength && shortTextLength !== dataLength)
      setDataLength(shortTextLength);

    // if container's length is available -> turn on/off spring view
    if (containerLength) {
      if (!springMode && containerLength < dataLength) setSpringMode(true);
      else if (springMode && containerLength > dataLength) setSpringMode(false);
    }
  }, [
    containerRef,
    dataLength,
    longTextRef,
    shortTextRef,
    springMode,
    windowHasUpdated,
  ]);

  const handleOnClick = () => {
    if (clickToCopy) navigator.clipboard.writeText(data);
    if (onClick) onClick();
    if (onClickGetData) onClickGetData(data);
  };

  return (
    <Container
      data-testid='spring-text__container'
      ref={containerRef}
      {...options}
      onClick={handleOnClick}
    >
      {!springMode && (
        <Full
          data-testid='spring-text__container_full'
          ref={shortTextRef}
          {...options}
        >
          {data}
        </Full>
      )}
      {springMode && (
        <LongText data-testid='spring-text__container_long' {...options}>
          <Left
            data-testid='spring-text__container_long_left'
            ref={longTextRef}
            {...options}
          >
            {data}
          </Left>
          <Divider
            data-testid='spring-text__container_long_divider'
            {...options}
          >
            {dividedBy ? dividedBy : '...'}
          </Divider>
          <Right data-testid='spring-text__container_long_right' {...options}>
            {data}
          </Right>
        </LongText>
      )}
    </Container>
  );
};
