import styled from 'styled-components/macro';

export const Address = styled.span`
  /* width: 1rem; */
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: center;
  /* @media (max-width: 940px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: auto;
    overflow: hidden;
    p {
      margin-inline-end: 0.5rem;
    }
  } */
`;
export const AddressTitle = styled.span`
  white-space: nowrap;
`;
