import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { sortInboxTransactions, isToHide } from 'src/process/tools';

import { AppState, CollectableExt } from 'src/store/models';
import { InboxActions, ApplicationActions } from 'src/store/actions';

import Card from 'src/components/Card';
import Empty from 'src/components/Empty';
import Page from 'src/layouts/Page';
import Loading from 'src/components/Loading';

import Inbox from './Inbox.style';

import TEXT from 'src/data';
import { COLORS } from 'src/style';

type RouterMatch = {
  params: {
    address?: string;
  };
};

export default () => {
  const {
    transactions,
    selected,
    isEmpty,
    address,
    isLoading,
    tip,
  } = useSelector((state: AppState) => ({
    ...state.inbox,
    ...state.user,
    ...state.app,
    isEmpty: !state.inbox.transactions.length,
  }));

  const dispatch = useDispatch();

  const handleOpenClose = (id: string): void => {
    dispatch({ type: InboxActions.SET_SELECTED, payload: id });
  };
  const handleCollect = (id: string, passcode: string) =>
    dispatch({ type: InboxActions.HANDLE_COLLECT, payload: { id, passcode } });



  const renderInbox = (): React.ReactElement => (
    <Page>
      <Inbox>
        {!isEmpty &&
          // sort transactions by id
          sortInboxTransactions(transactions).map(
            (trx: CollectableExt) =>
              // if transaction should be hidden, then show it
              !isToHide(trx,tip) && (
                <Card
                  collect={handleCollect}
                  key={trx.id}
                  select={handleOpenClose}
                  isOpen={selected.includes(trx.id) || (trx.isLoading||false)}
                  transaction={trx}
                />
              ),
          )}
        {!isLoading && isEmpty && <Empty message={TEXT.inbox.empty} />}
      </Inbox>
      <Loading play={isLoading} color={COLORS.blue.primary} />
    </Page>
  );

  // if address has been checked and set before, it's safe to render Inbox
  if (address) return renderInbox();

  // otherwise, this is a direct link, so need to start the process
  const match = useRouteMatch('/inbox/:address') as RouterMatch;

  // if path doesn't have match pattern === address was not provided > show message and redirect
  if (!match) {
    dispatch({
      type: ApplicationActions.SET_MESSAGE,
      payload: {
        code: 500,
        text: TEXT.inbox.address.missing,
      },
    });
    dispatch({ type: InboxActions.HANDLE_RETURN });
  }

  // get requested address
  const requestedAddress = match?.params.address ?? '';

  dispatch({
    type: InboxActions.HANDLE_CHECK_ADDRESS,
    payload: requestedAddress,
  });

  return renderInbox();
};
