import React from 'react';

import { P6 } from 'src/style';

interface Props {
  message: string;
}

export default ({ message }: Props): React.ReactElement => (
  <P6 textAlign='center' margin='3rem 0 0 0'>
    {message}
  </P6>
);
