import React, { useState } from 'react';

import Tooltip, { TooltipSide } from 'src/components/Tooltip/';

import Button, { ClickableMotions } from './Clickable.style';

interface Props {
  flat?: boolean;
  size?: number;
  color?: string;
  margin?: string;
  padding?: string;
  motion?: ClickableMotions;
  children: JSX.Element;
  tooltip?: { side: TooltipSide; title: string };
  onClick: () => void;
}

/**
 * React component to render button
 * @param {ButtonProps} param Button parameters and children
 * @returns React.ReactElement
 */
export default ({
  flat,
  size,
  color,
  motion,
  children,
  tooltip,
  onClick,
  margin,padding
}: Props): React.ReactElement => {
  const [isHover, setIsHover] = useState(false);
  const testId = `button__${flat ? 'flat' : 'default'}`;
  return (
    <Button
      data-testid={testId}
      flat={flat || false}
      size={size}
      color={color}
      motion={motion}
      margin={margin}
      padding={padding}
      onClick={(): void => onClick()}
      onMouseEnter={(): false | void => !!tooltip && setIsHover(true)}
      onMouseLeave={(): false | void => !!tooltip && setIsHover(false)}
    >
      {children}
      {!!tooltip && isHover && (
        <Tooltip side={tooltip.side} width={4.5} label={tooltip.title} />
      )}
    </Button>
  );
};
