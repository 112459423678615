import React from 'react';
import Loading from './Loading.style';

interface Props {
  play: boolean | undefined;
  size?: number;
  margin?: number;
  icon?: boolean;
  color?: string;
}
export default ({ play, size, margin, icon, color }: Props) => (
  <Loading size={size} margin={margin} color={color}>
    {play && (
      <>
        <span />
        <span />
        <span />
        <span />
      </>
    )}
  </Loading>
);
