const TEXT = {
  return: 'Exit',
  navigation: { 404: 'Page not found' },
  header: {
    return: 'Change Address',
    addressCopied: 'Address copied'
  },
  api: {
    connect: 'Connected to API',
    disconnect: 'Disconnected from API',
  },
  welcome: {
    title: 'Collect BTC safely',
    subtitle: "Please enter your recipient's wallet address below",
    instructions: {
      title: 'Instruction',
      message: `To receive the funds, please enter the recipient's address, passcode and
    press 'Check inbox' button.`,
    },
    input: {
      placeholder: "Enter recipient's address...",
      button: 'Check inbox',
    },
  },
  inbox: {
    title: 'Collecting transactions for:',
    address: {
      missing: 'Address is not provided',
      invalid: 'Invalid address',
    },
    empty: 'Waiting for transaction...',
    transaction: {
      to: 'To:',
      collect: 'Collect',
      cancel: 'Cancel',
      confirmations: {
        confirmed: 'Confirmed',
        waiting: 'Waiting for confirmation',
      },
    },
    collect: {
      passcode: 'Passcode:',
      input: {
        placeholder: 'Enter passcode...',
      },
      button: 'Collect',
      wrongPasscode: 'Wrong passcode',
      broadcasted: 'Request submitted.',
    },
  },
};

export default TEXT;
