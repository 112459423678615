import React, { ChangeEvent, useState } from 'react';

import { COLORS } from 'src/style';
import { Input, Label, Inline } from './Input.style';
import Loading from 'src/components/Loading';
import { Line } from 'src/style';
import { Eye } from 'src/icons';
import Clickable from 'src/hocs/Clickable';

export enum InputType {
  PASSWORD = 'password',
  STRING = 'text',
  TEXT = 'textarea',
}

export interface InputOptions {
  background?: string;
  center?: boolean;
  padding?: string;
  width?: string;
  maxWidth?: string;
}

export interface InputLabel {
  title?: string;
  placeholder?: string;
}

export interface InputEvents {
  onChange: (arg0: string) => void;
  onEnter?: () => void;
}

export interface Props {
  autofocus?: boolean;
  events: InputEvents;
  isLoading?: boolean;
  label: InputLabel;
  options: InputOptions;
  showButton?: boolean;
  type: InputType;
  value: string;
}
export default ({
  autofocus,
  events: { onChange, onEnter },
  isLoading,
  label: { title, placeholder },
  showButton,
  type,
  value,
  options,
  options: { center },
}: Props): React.ReactElement => {
  const [show, setShow] = useState(false);

  const handleToggleShow = () => setShow(!show);

  const inputType = () => {
    if (type === InputType.PASSWORD) return show ? 'text' : 'password';
    return type;
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value);

  const handleKeyPress = (e: any) => e.key === 'Enter' && onEnter && onEnter();

  return (
    <Line justify={center ? 'center' : 'space-between'}>
      {title && <Label>{title}</Label>}
      <Input
        onKeyPress={handleKeyPress}
        autoFocus={autofocus}
        placeholder={placeholder}
        value={value}
        type={inputType()}
        onChange={handleOnChange}
        {...options}
      />
      <Inline single={!showButton}>
        <Loading size={2.5} play={isLoading} color={COLORS.blue.primary} />
        {showButton && (
          <Clickable onClick={handleToggleShow}>
            <Eye
              options={{
                arc: {
                  path: show ? COLORS.red.primary : COLORS.blue.primary,
                  strokeWidth: '4',
                },
                circle: {
                  stroke: show ? COLORS.red.primary : COLORS.blue.primary,
                  fill: show ? COLORS.red.primary : '',
                  strokeWidth: '4',
                },
              }}
            />
          </Clickable>
        )}
      </Inline>
    </Line>
  );
};
