import styled from 'styled-components/macro';

export const Wrapper = styled.main`
  display: flex;
  flex-flow: column;
`;

const Inbox = styled.section`
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
`;

export default Inbox;
