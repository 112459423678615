import { Collectable } from '@kiroboio/safe-transfer-lib';

const sortArrayByStringKey = (
  data: Collectable[],
): Collectable[] =>
  data.sort((a, b) => a.id.localeCompare(b.id));

export const sortInboxTransactions = (
  transactions: Collectable[],
): Collectable[] => [...sortArrayByStringKey(transactions)];

export const isMessage = (data: any): boolean =>
  data &&
  Object.keys(data).includes('code') &&
  Object.keys(data).includes('text');
