import styled from 'styled-components/macro';
import { generateString } from 'src/toolbelt';

interface SpacerProps {
  vSpace?: number;
  hSpace?: number;
}

export const Spacer = styled.span<SpacerProps>`
  ${(props: SpacerProps): string | undefined =>
    props.vSpace
      ? generateString('margin-top', props.vSpace, 'rem')
      : undefined}
  ${(props: SpacerProps): string | undefined =>
    props.hSpace
      ? generateString('margin-inline-start', props.hSpace, 'rem')
      : undefined}
`;
