import React, { useState, useEffect } from 'react';

import Container, { Card } from './Card.style';

import Slider from 'src/components/Slider';

import Left from './Left';
import Right from './Right';

import { CollectableExt } from 'src/store/models';

interface Props {
  collect: (arg0: string, arg1: string) => void;
  isOpen: boolean;
  select: (arg0: string) => void;
  transaction: CollectableExt;
}

export default ({
  collect,
  isOpen,
  select,
  transaction,
}:
Props): React.ReactElement => {
  const [passcode, setPasscode] = useState('');

  const isLoading = transaction.isLoading||false;

  const { amount, from, id, state } = transaction

  const toggleOpen = (): void => {
    // clear passcode if open/closed
    if (passcode) setPasscode('');
    select(id as string);
  };

  useEffect(() => {
    // if state changes -> clear passcode
    setPasscode('');
  }, [state]);

  const handleCollect = (): void => {
    if (id && passcode) {
      // collect current transaction
      collect(id, passcode);
      // deselect current transaction, so when it's not loading, the slider will close
      select(id);
    }
  };

  return (
    <Container>
      <Card>
        <Left from={from} amount={amount} />
        <Right
          transaction={transaction}
          isOpen={isOpen}
          toggleOpen={toggleOpen}
        />
      </Card>
      {isOpen && (
        <Slider
          collect={handleCollect}
          isLoading={isLoading}
          isOpen={isOpen}
          passcode={passcode}
          setPasscode={setPasscode}
        />
      )}
    </Container>
  );
};
