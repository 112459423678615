/* eslint-disable spaced-comment */
import styled, { css, FlattenInterpolation } from 'styled-components/macro';

import {
  COLORS,
  rotateClock90,
  rotateBackFromClock90,
  transit,
} from 'src/style';

export enum ClickableMotions {
  CLOCK = 'clock',
  COUNTER = 'counter',
  STILL = 'still',
}

const aniClock = (
  direction: ClickableMotions,
): FlattenInterpolation<string> => css`
  animation: ${direction === ClickableMotions.CLOCK
      ? rotateClock90
      : rotateBackFromClock90}
    0.4s ease-in-out forwards;
`;

interface ClickableProps {
  flat: boolean;
  size?: number;
  color?: string;
  motion?: ClickableMotions;
  margin?: string;
  padding?: string;
}

const Clickable = styled.button<ClickableProps>`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: ${({ margin }: ClickableProps): string | undefined =>
    margin || undefined};
  padding: ${({ padding }: ClickableProps): string | undefined =>
    padding || undefined};
  height: ${(props: ClickableProps): number =>
    props.size ? props.size : 2}rem;
  width: ${(props: ClickableProps): number => (props.size ? props.size : 2)}rem;
  border-radius: 0.5rem;
  /* padding: 0.5rem; */
  ${transit()}
  ${(props: ClickableProps): any | null =>
    props.motion && props.motion !== ClickableMotions.STILL
      ? aniClock(props.motion)
      : null}
  svg {
    height: 100%;
    fill: ${(props: ClickableProps): string =>
      props.color ? props.color : COLORS.menu.primary};
  }
`;

export default Clickable;
