export type Message = {
  code: number;
  text: string;
};

export interface ApplicationState {
  path: string;
  module: string;
  isLoading: boolean;
  message: Message;
}

export const EMPTY_MESSAGE = {
  code: 0,
  text: '',
};

export const INITIAL_APPLICATION_STATE: ApplicationState = {
  path: '',
  module: '',
  isLoading: false,
  message: EMPTY_MESSAGE,
};

