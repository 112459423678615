import React from 'react';

import { IconProps } from './types';

/**
 * React component to render SVG icon
 */
export const Info = ({ fill, size }: IconProps): React.ReactElement => (
  <svg
    data-testid='icon__information'
    xmlns='http://www.w3.org/2000/svg'
    fillRule='evenodd'
    strokeLinejoin='round'
    strokeMiterlimit='2'
    clipRule='evenodd'
    viewBox={size ? `0 0 ${size} ${size}` : '0 0 512 512'}
  >
    <path
      fillRule='nonzero'
      fill={fill || 'white'}
      d='M256 6C118 6 6 118 6 256s112 250 250 250 250-112 250-250S394 6 256 6zm25 375h-50V231h50v150zm0-200h-50v-50h50v50z'
    ></path>
  </svg>
);
