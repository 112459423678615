import styled from 'styled-components/macro';

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
`;

export default Footer;
