/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import { P2, COLORS } from 'src/style';
import { Info } from 'src/icons';

import Flash from './Flash.style';

export enum FlashTypes {
  INFO = 'info',
  CONFIRMATION = 'confirmation',
  WARNING = 'warning',
}
export enum FlashTo {
  APPEAR = 'appear',
  DISAPPEAR = 'disAppear',
}
interface FlashProps {
  type: FlashTypes;
  message: string;
  delay?: number;
  wrapper?: boolean;
  removeMe: () => void;
}

/**
 * React component to render flash message
 * @param {FlashProps} props Data to render and function to close the message
 */
export default ({
  type,
  message,
  delay,
  wrapper,
  removeMe,
}: FlashProps): React.ReactElement => {
  const [isToAppear, setIsToAppear] = useState(FlashTo.APPEAR);
  const iconComponent: JSX.Element = <Info />;
  let timer: number;

  const makeTimer = () =>
    setTimeout(
      () => {
        setIsToAppear(FlashTo.DISAPPEAR);
        setTimeout(() => {
          removeMe();
        }, 500);
      },

      typeof delay !== 'undefined' ? delay : 5000,
    );

  // component is always attached, but not displayed; when message arrives - it
  // shows it and sets new timer, every time message changes;
  useEffect(() => {
    if (timer) clearTimeout(timer);
    timer = makeTimer();
  }, [message]);

  const testId = `flash__${type}`;

  let color = COLORS.grey.primary;
  let colorBackground = COLORS.grey.secondary;

  if (type === FlashTypes.CONFIRMATION) {
    color = COLORS.white;
    colorBackground = COLORS.green.primary;
  }
  if (type === FlashTypes.WARNING) {
    color = COLORS.white;
    colorBackground = COLORS.red.primary;
  }

  return (
    <Flash
      color={color}
      colorBackground={colorBackground}
      wrapper={wrapper}
      data-testid={testId}
      to={isToAppear}
      onClick={() => removeMe()}
    >
      <div>{iconComponent}</div>
      <P2>{message}</P2>
    </Flash>
  );
};
