import { Collectable } from '@kiroboio/safe-transfer-lib';
import { CollectableExt } from 'src/store/models';
import { generateDecryptionKey } from '@kiroboio/safe-transfer-crypto';

export const isToHide = (trx: Collectable, height: number): boolean => {
  if (trx.state !== 'collected') return false;
  if (trx.collect.confirmed < 1) return false;
  if (height - trx.collect.confirmed + 1 > 6) return true;
  return false;
};

export const makeCollectableObject = (
  transaction: CollectableExt,
  passcode: string,
) => ({
  id: transaction.id,
  key: generateDecryptionKey({
    passcode,
    salt: transaction.salt,
  }),
});
