import { EventTypes } from '@kiroboio/safe-transfer-lib';

import ProcessorClass from 'src/process/processor';
import { makeStatus } from 'src/process/tools';
import { ReduxStore } from 'src/process/types';
import {
  UserActions,
  InboxActions,
  ApplicationActions,
  Action,
} from 'src/store/actions';

let Processor: ProcessorClass;

const dispatchAction = (dispatch: (arg0: Action) => void) => (
  type: InboxActions | ApplicationActions | UserActions,
) => (payload?: any) => dispatch({ type, payload });

export default (store: ReduxStore) => (next: any) => (action: any) => {
  if (!Processor) Processor = new ProcessorClass({ store });

  const dispatch = dispatchAction(store.dispatch);
  const changeModule = dispatch(ApplicationActions.CHANGE_MODULE);
  const changePath = dispatch(ApplicationActions.CHANGE_PATH);
  const address = dispatch(UserActions.SET_ADDRESS);
  const inbox = dispatch(InboxActions.SET_INBOX);
  const status = dispatch(InboxActions.SET_STATUS);
  const isOnline = dispatch(InboxActions.SET_IS_ONLINE);
  const tip = dispatch(InboxActions.SET_TIP);

  switch (action.type) {
    // USING LIB

    // every time service is pushing status update, make status and
    // store it
    case EventTypes.UPDATE_STATUS:
      status(
        makeStatus({
          height: action.payload.height,
          online: action.payload.online,
          netId: Processor.getSettings().network,
          timestamp: new Date(),
        }),
      );
      tip(action.payload.height);
      isOnline(action.payload.online)
      break;
    case EventTypes.GET_COLLECTABLES:
      Processor.setInbox(action.payload);
      break;
    case InboxActions.HANDLE_CHECK_ADDRESS:
      Processor.getCollectables(action.payload);
      break;
    case InboxActions.HANDLE_COLLECT:
      const { id, passcode } = action.payload;
      // if ID and passcode present, clear messages and attempt to collect
      if (id && passcode) {
        Processor.collect(id, passcode);
      }
      break;
    case InboxActions.HANDLE_RETURN:
      // when return back to home page:
      //  - clear inbox
      //  - clear address
      //  - change path to 'Welcome'
      inbox([]);
      address('');
      changePath(`/`);
      changeModule('welcome');
      break;
    // refresh data on reconnect
    case 'REFRESH':
      Processor.getCollectables(store.getState().user.address);
      break;
    default:
  }
  next(action);
};
